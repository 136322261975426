// colors
$darkBlue: #101011;
$blue: #1c56a3;
$tan: hsl(40, 45%, 92%);
$grey: #edeef4;
$mediumGrey: #cecece;
$darkGrey: #929292;
$white: #ffffff;
$gradient: linear-gradient(
    27deg,
    rgba(16, 55, 108, 1) 0%,
    rgba(28, 86, 163, 1) 100%
);
$red: #c85827;
$yellow: #bda800;

// font
$fontFamily: poppins;
$headingFontSize: 12.5rem;
$headingFontSizeClamp: clamp(5.95rem, 6vw, 12.5rem);
$headingFontSizeClamp02: clamp(4.25rem, 6vw, 12.5rem);
$subheadingFontSize: 6.5rem;
$subheadingFontSizeClamp: clamp(4rem, 7vw, 6.5rem);
$subhgiteadingTwoFontSize: 2.7rem;
$subheadingTwoFontSizeClamp: clamp(1.25rem, 1.25vw, 2.7rem);
$subheadingThreeFontSize: 4.5rem;
$subheadingThreeFontSizeClamp: clamp(2rem, 7vw, 4.5rem);
$subheadingFourFontSizeClamp: clamp(0.2rem, 2vw, 2.5rem);
$subheadingFiveFontSizeClamp: clamp(0.2rem, 2vw, 2rem);
$paragraphFontSize: 2rem;
$paragraphFontSizeClamp: clamp(1.19rem, 0.9vw, 2rem);
$paragraphFontSizeTwoClamp: clamp(1.75rem, 0.9vw, 2rem);
$paragraphFontSizeThreeClamp: clamp(1.75rem, 0.9vw, 2rem);
$paragraphFontSpacing: 4rem;
$paragraphFontSpacingClamp: clamp(3rem, 2vw, 4rem);
$fontButton: clamp(1.5rem, 1.25vw, 2.7rem);
$navFontSizeClamp: clamp(1.3rem, 1vw, 1.8rem);
$navPortfolioToggleSizeClamp: clamp(1.15rem, 1.25vw, 1.5rem);
$navPortfolioMobileFontSize: 1rem;

//padding
$desktopNavPadding: 2rem 13vw;
$desktopPadding: 0 13vw;
$ipadNavPadding: 4rem 10vw;
$ipadPadding: 0 10vw;
$mobileNavPadding: 4rem 7vw;
$mobilePadding: 0 7vw;
$mobileFooterPadding: 5rem 7vw;

//navbar
$logoSize: clamp(5rem, 20vw, 20rem);

//poster clamp
$posterClamp: clamp(35rem, 50vw, 70rem);

// border radius
$borderRadius: 2rem;
$borderRadiusTwo: 1rem;
$borderRadiusThree: 0.8rem;

// body default

@mixin body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

@mixin bodyDefault {
    font-size: 62.5%;
    box-sizing: border-box;
    font-family: $fontFamily;
    scroll-behavior: smooth;
}

// main default
@mixin main {
    height: 100%;
}

// section default
@mixin section {
    height: 100vh;
    padding: $desktopPadding;
}

@mixin sectionTablet {
    height: 100%;
    padding: $ipadPadding;
}

@mixin sectionMobile {
    height: 100%;
    padding: $mobilePadding;
}

// scrollBelow
@mixin scrollBelow {
    position: absolute;
    bottom: 5rem;
    left: calc(50% - 6rem);

    img {
        width: 12rem;
        height: auto;
    }
}

// background logo
@mixin backgroundLogo {
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    bottom: 5%;
    right: -5%;
    z-index: -1;

    svg {
        height: auto;
        width: 55rem;
    }
}

@mixin backgroundLogoMobile {
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    top: 45rem;
    right: -5rem;
    z-index: -1;

    svg {
        height: auto;
        width: 35rem;
    }
}

// contact nav Button
@mixin contactButton {
    background-color: transparent;
    padding: 1.5rem 7rem;
    border-radius: 5rem;
    font-weight: 500;
    text-transform: capitalize;
    color: $darkBlue;
    border: 1px solid $darkBlue;
    font-family: $fontFamily;
    font-size: $navFontSizeClamp;
}

@mixin contactButtonHover {
    background: $gradient;
    padding: 1.5rem 7rem;
    border-radius: 5rem;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 1.8rem;
    color: $white;
    border: none;
    border: 1px solid transparent;
    transition: 0.25s all;
    cursor: pointer;
    font-family: $fontFamily;
    font-size: $navFontSizeClamp;
}

// default button
@mixin buttonNeutral {
    background-color: transparent;
    padding: 1.25rem clamp(6rem, 1.5vw, 10rem);
    border-radius: 5rem;
    font-family: $fontFamily;
    font-weight: 600;
    font-size: $fontButton;
    color: $blue;
    border: 1px solid $blue;
    transition: 0.25s all;
    text-transform: capitalize;
}

@mixin buttonNeutralHover {
    background: $gradient;
    padding: 1.25rem clamp(6rem, 1.5vw, 10rem);
    border-radius: 5rem;
    font-family: $fontFamily;
    font-weight: 600;
    font-size: $fontButton;
    color: $white;
    border: 1px solid transparent;
    transition: 0.25s all;
    cursor: pointer;
}

@mixin buttonNeutral02 {
    background-color: transparent;
    padding: 1.25rem clamp(3rem, 1.5vw, 10rem);
    border-radius: 5rem;
    font-family: $fontFamily;
    font-weight: 600;
    font-size: $fontButton;
    color: $blue;
    border: 1px solid $blue;
    transition: 0.25s all;
    text-transform: capitalize;
}

@mixin buttonNeutralHover02 {
    background: $gradient;
    padding: 1.25rem clamp(3rem, 1.5vw, 10rem);
    border-radius: 5rem;
    font-family: $fontFamily;
    font-weight: 600;
    font-size: $fontButton;
    color: $white;
    border: 1px solid transparent;
    transition: 0.25s all;
    cursor: pointer;
}

// lets chat button
@mixin letsChatButton {
    background-color: transparent;
    border: none;
    font-family: $fontFamily;
    font-weight: 600;
    font-size: $fontButton;
    color: $blue;
    cursor: pointer;
    transition: 0.25s all;
    text-transform: capitalize;
}

@mixin letsChatButtonHover {
    content: "";
    position: absolute;
    background-color: $blue;
    width: 2rem;
    height: 1rem;
    margin: clamp(0rem, 1.75em, 4.25rem) 0 0 0;
    cursor: pointer;
    transition: 0.25s all;
    transform: scaleY(0);
}

// tan underline
@mixin tanUnderline {
    content: "";
    width: clamp(33rem, 35vw, 82rem);
    height: clamp(1.25vh, 1vw, 2vh);
    background-color: $tan;
    position: absolute;
    z-index: -1;
    border-radius: 2rem;
}

@mixin tanUnderLineTablet {
    content: "";
    width: clamp(35rem, 35vw, 82rem);
    height: clamp(1.25vh, 1vw, 2vh);
    background-color: $tan;
    position: absolute;
    z-index: -1;
    border-radius: 2rem;
}

//heading
@mixin headingDarkBlue {
    font-size: $headingFontSizeClamp;
    color: $darkBlue;
    font-family: $fontFamily;
}

@mixin headingLightBlue {
    font-size: $headingFontSizeClamp;
    color: $blue;
    font-family: $fontFamily;
}

//subHeading
@mixin subHeading {
    text-transform: uppercase;
    font-size: clamp(3.75rem, 3.75vw, 6.5rem);
    font-weight: 700;
}

//subHeading Two
@mixin subHeadingTwo {
    font-size: $subheadingTwoFontSizeClamp;
    font-family: $fontFamily;
}

//subHeading Two
@mixin subHeadingThree {
    font-size: $subheadingThreeFontSizeClamp;
    font-family: $fontFamily;
    text-transform: uppercase;
}

@mixin subHeadingFour {
    font-size: $subheadingFourFontSizeClamp;
    font-family: $fontFamily;
    text-transform: uppercase;
}

@mixin subHeadingFive {
    font-size: $subheadingFiveFontSizeClamp;
    font-family: $fontFamily;
    text-transform: uppercase;
}

// paragraph
@mixin paragraph {
    font-size: $paragraphFontSizeClamp;
    font-family: $fontFamily;
    line-height: $paragraphFontSpacingClamp;
    font-weight: 400;
}

@mixin paragraphTwo {
    font-size: $paragraphFontSizeTwoClamp;
    font-family: $fontFamily;
    line-height: 4rem;
    font-weight: 400;
}

// navbar
@mixin navBarDesktop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-box-shadow: 0px 35px 25px -10px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 35px 25px -10px rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 3;
    padding: $desktopNavPadding;
    width: calc(100% - 26vw);
    backdrop-filter: blur(1rem);

    .mobileLogo {
        display: none;
    }

    .desktopLogo {
        img {
            width: 28rem;
            cursor: pointer;
        }
    }

    .navList {
        display: flex;
        align-items: center;
        justify-content: center;

        ul {
            display: flex;
            list-style: none;
            list-style-type: none;

            li {
                position: relative;
                padding: 0 2rem;

                a {
                    @include navBarStyleDesktop;
                }

                a.navResume::before {
                    transform: scaleY(1) !important;
                }
            }

            .navPortfolio {
                @include navBarStyleDesktop;

                a.portfolioToggleFont {
                    @include navBarStyleDesktop;
                }

                a::before {
                    @include navBarToggleBeforeStyle;
                }

                a#portfolioToggleFontUXUI.portfolioToggleFont:hover:before {
                    @include navBarToggleBeforeStyle;
                    width: clamp(6rem, 8vw, 9.5rem);
                }

                a#portfolioToggleFontGraphicDesign.portfolioToggleFont:hover:before {
                    @include navBarToggleBeforeStyle;
                    width: clamp(6rem, 9.5vw, 11.5rem);
                }

                a#portfolioToggleFontArchitecture.portfolioToggleFont:hover:before {
                    @include navBarToggleBeforeStyle;
                    width: clamp(6rem, 7.5vw, 9rem);
                }

                a#portfolioToggleFontDigitalArtwork.portfolioToggleFont:hover::before {
                    @include navBarToggleBeforeStyle;
                    width: clamp(6rem, 9vw, 10rem);
                }

                a#portfolioToggleFontFrontendDevelopment.portfolioToggleFont:hover:before {
                    @include navBarToggleBeforeStyle;
                    width: clamp(6rem, 14vw, 17.25rem);
                }
            }
        }

        .navHome::before {
            @include navHover;
        }

        .navHome:hover::before {
            @include navHoverActive;
        }

        .navPortfolio::before {
            @include navHover;
        }

        .navPortfolio.active::before {
            @include navHoverActive;
        }

        .navPortfolio:hover::before {
            @include navHoverActive;
        }

        .navAbout::before {
            @include navHover;
        }

        .navAbout:hover::before {
            @include navHoverActive;
        }

        .navResume::before {
            @include navHover;
        }

        .navResume:hover::before {
            @include navHoverActive;
        }

        .portfolioToggle {
            position: absolute;
            background-color: $white;
            -webkit-box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.15);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            top: 5rem;
            margin: 0;
            padding: 0.5rem 2rem;
            width: max-content;
            border-radius: 2rem;
            visibility: hidden;
            transform: translateY(-35rem);
            transition: 0.5s all;
            z-index: 3;

            li {
                list-style: none;
                padding: 1rem 0;

                a.portfolioToggleFont {
                    @include portfolioToggleStyle;
                }

                a.portfolioToggleFont:hover {
                    @include portfolioToggleStyleHover;
                }
            }
        }

        .portfolioToggle.active {
            visibility: visible;
            transform: translateY(0rem);
            transition: 0.5s all;
        }
    }

    .contactButtonContainer {
        padding: 0 0 0 2rem;

        .contactButton {
            @include contactButton;
        }

        .contactButton:hover {
            @include contactButtonHover;
        }
    }

    .mobileTrigger {
        display: none;
    }
}

@mixin navBarTablet {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $ipadNavPadding;
    width: calc(100% - 20vw);
    -webkit-box-shadow: 0px 35px 25px -10px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 35px 25px -10px rgba(0, 0, 0, 0.05);
    z-index: 3;

    .desktopLogo {
        display: none;
    }

    .navList {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-height: 0;
        opacity: 0%;
        transform: translateY(-9rem);
        visibility: hidden;
        transition: 0.5s all;
        z-index: 3;

        ul {
            flex-direction: column;
            align-items: center;
            padding: 0;

            li {
                padding: 1rem 0;
            }

            .navPortfolio {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                display: inherit;
                z-index: 3;

                .portfolioToggle {
                    text-align: center;
                    position: inherit;
                    background-color: transparent;
                    box-shadow: none;
                    top: inherit;
                    margin: 0;
                    padding: 0;
                    width: inherit;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    display: none;
                    padding: 0;
                    transition: 0.5s all;

                    li {
                        list-style: none;
                        padding: 0.25rem 0;

                        a.portfolioToggleFont {
                            @include portfolioToggleStyle;
                        }
                    }
                }
            }

            li.navPortfolio::before {
                display: none;
            }
        }

        a::before {
            display: none;
        }

        a:hover:before {
            display: none;
        }
    }

    .contactButtonContainer {
        display: initial;
        padding: 0;
    }

    .mobileLogo {
        display: initial;
        position: absolute;
        left: 10vw;
        top: 3rem;
        cursor: pointer;
    }

    .mobileTrigger {
        display: initial;
        content: "";
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
        position: absolute;
        right: 10vw;
        top: 3rem;
        z-index: 2;
        background-color: transparent;

        .trigger {
            display: initial;
            content: "";
            width: 2.5rem;
            height: 2.5rem;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background-color: transparent;
        }

        .mobileToggle {
            @include mobileToggle;
        }

        .mobileToggle::after {
            @include mobileToggleAfter;
        }

        .mobileToggle::before {
            @include mobileToggleBefore;
        }

        .mobileToggle.active {
            @include mobileToggle;
            width: 0;
            transition: 0.5s all;
        }

        .mobileToggle.active::after {
            @include mobileToggleAfter;
            transform: rotate(45deg);
            bottom: 0rem;
            transition: 0.5s all;
        }

        .mobileToggle.active::before {
            @include mobileToggleBefore;
            transform: rotate(-45deg);
            top: 0rem;
            transition: 0.5s all;
        }
    }
}

@mixin navBarTabletActive {
    .navList {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-height: 55vh;
        opacity: 100%;
        transform: translateY(0rem);
        visibility: visible;
        transition: 0.5s all;
        z-index: 3;

        ul {
            flex-direction: column;
            align-items: center;
            padding: 0;

            li {
                padding: 1rem 0;
            }

            .navPortfolio {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                display: inherit;
                z-index: 3;

                .portfolioToggle {
                    text-align: center;
                    position: inherit;
                    background-color: transparent;
                    box-shadow: none;
                    top: inherit;
                    margin: 0;
                    padding: 0;
                    width: inherit;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 0;
                    padding: 0;
                    transition: 0.5s all;

                    li {
                        list-style: none;
                        padding: 0.25rem 0;
                        opacity: 0%;

                        a.portfolioToggleFont {
                            @include portfolioToggleStyle;
                        }
                    }
                }

                .portfolioToggle.active {
                    height: 15rem;
                    transition: 0.5s all;
                    padding: 2rem 0 0 0;

                    li {
                        opacity: 100%;
                        transition: 0.5s all;
                        padding: 0.5rem 0;
                    }
                }
            }

            .navPortfolio:hover::before {
                transform: scaleY(0);
            }

            a::before {
                display: none;
            }

            a:hover:before {
                display: none;
            }

            li::before {
                display: none;
            }
        }
    }

    .mobileLogo {
        display: initial;
        margin: 0 0 22rem 0;
    }

    .mobileTrigger {
        display: initial;
        content: "";
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
        position: absolute;
        right: 10vw;
        top: 3rem;

        .mobileToggle {
            @include mobileToggle;
        }

        .mobileToggle::after {
            @include mobileToggleAfter;
        }

        .mobileToggle::before {
            @include mobileToggleBefore;
        }

        .mobileToggle.active {
            @include mobileToggle;
            width: 0;
            transition: 0.5s all;
        }

        .mobileToggle.active::after {
            @include mobileToggleAfter;
            transform: rotate(45deg);
            bottom: 0rem;
            transition: 0.5s all;
        }

        .mobileToggle.active::before {
            @include mobileToggleBefore;
            transform: rotate(-45deg);
            top: 0rem;
            transition: 0.5s all;
        }
    }
}

@mixin navBarMobile {
    padding: $mobileNavPadding;
    width: calc(100% - 14vw);

    .mobileLogo {
        left: 7vw;
        top: 3rem;
    }

    .mobileTrigger {
        right: 7vw;
        top: 3rem;

        .mobileToggle {
            @include mobileToggle;
        }

        .mobileToggle::after {
            @include mobileToggleAfter;
        }

        .mobileToggle::before {
            @include mobileToggleBefore;
        }

        .mobileToggle.active {
            @include mobileToggle;
            width: 0;
            transition: 0.5s all;
        }

        .mobileToggle.active::after {
            @include mobileToggleAfter;
            transform: rotate(45deg);
            bottom: 0rem;
            transition: 0.5s all;
        }

        .mobileToggle.active::before {
            @include mobileToggleBefore;
            transform: rotate(-45deg);
            top: 0rem;
            transition: 0.5s all;
        }
    }

    .navList {
        .navPortfolio {
            .navPortfolio:hover::before {
                display: none;
            }
        }
    }
}

@mixin navBarMobileActive {
    padding: $mobileNavPadding;
    width: calc(100% - 14vw);

    .mobileTrigger {
        right: 7vw;
        top: 3rem;
    }

    .navList {
        .navPortfolio {
            .navPortfolio:hover::before {
                display: none;
            }
        }
    }
}

@mixin navBarStyleDesktop {
    text-decoration: none;
    color: $darkBlue;
    font-size: $navFontSizeClamp;
    font-family: $fontFamily;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;
}

@mixin navBarToggleBeforeStyle {
    content: "";
    position: absolute;
    background-color: $tan;
    height: 1rem;
    width: 0;
    transition: 0.25s all;
    z-index: -1;
    border-radius: 3rem;
}

@mixin portfolioToggleStyle {
    text-decoration: none;
    color: $darkBlue;
    font-size: $navPortfolioToggleSizeClamp;
    font-family: $fontFamily;
    text-transform: capitalize;
}

@mixin portfolioToggleStyleHover {
    text-decoration: none;
    color: $darkBlue;
    font-size: $navPortfolioToggleSizeClamp;
    font-family: $fontFamily;
    text-transform: capitalize;
}

// footer
@mixin defaultFooterDesktop {
    height: 40rem;
    background-color: $darkBlue;
    display: flex;
    flex-direction: column;
    padding: $desktopPadding;

    .contents {
        height: 40rem;
        background-color: #101011;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .logo {
            img {
                width: auto;
                height: 5vh;
                cursor: pointer;
            }
        }

        .footerList {
            display: flex;
            flex-direction: row;
            h1 {
                @include footerHeading;
            }

            ul {
                list-style: none;
                text-decoration: none;
                padding: 0;
            }

            li {
                list-style: none;
                text-decoration: none;
                padding: 0;
            }

            a {
                @include footerParagraph;
                z-index: 1;
            }

            .footerPortfolio {
                margin: 0 10rem;

                a {
                    position: relative;
                    z-index: 1;
                }

                a::before {
                    @include footereBeforeStyleDesktop;
                }

                a.footerUXUI:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 8rem;
                }

                a.footerGraphicDesign:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 10rem;
                }

                a.footerArchitecture:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 8rem;
                }

                a.footerDigitalArtwork:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 9rem;
                }

                a.footerFrontendDevelopment:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 14rem;
                }
            }

            .footerSocials {
                margin: 0 10rem 0 0;

                a {
                    position: relative;
                    z-index: 3;
                }

                a::before {
                    @include footereBeforeStyleDesktop;
                }

                a.footerFacebook:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 6rem;
                }

                a.footerInstagram:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 7rem;
                }

                a.footerTiktok:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 4rem;
                }

                a.footerYoutube:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 5rem;
                }

                a.footerGithub:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 4rem;
                }
            }

            .footerContact {
                margin: 0 0 0 0;

                a {
                    position: relative;
                    z-index: 3;
                }

                a::before {
                    @include footereBeforeStyleDesktop;
                }

                a.footerContact:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 5rem;
                }

                a.footerLinkedin:hover:before {
                    @include footereBeforeStyleDesktop;
                    width: 5rem;
                }
            }
        }
    }

    .copyrightContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 0 2rem 0;

        p.copyright {
            @include footerParagraph;
            margin: 0;
            padding: 0;
            color: $darkGrey;
            font-weight: lighter;
        }
    }
}

@mixin defaultFooterTablet {
    @include defaultFooterMobile;
}

@mixin defaultFooterMobile {
    background-color: $darkBlue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;

    .contents {
        height: auto;
        .logo {
            display: none;
        }

        .footerList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 11.5rem 0 8rem 0;

            h1 {
                @include footerHeading;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                list-style: none;
                text-decoration: none;
                padding: 0;
            }

            li {
                list-style: none;
                text-decoration: none;
                padding: 0;
            }

            a {
                @include footerParagraph;
            }

            .footerPortfolio {
                align-items: center;
                justify-content: center;
                margin: 0;
            }

            .footerSocials {
                align-items: center;
                justify-content: center;
                margin: 0;
            }

            .footerContact {
                align-items: center;
                justify-content: center;
                margin: 0;
                padding: 0;
            }
        }
    }

    .copyrightContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 0 2rem 0;

        p.copyright {
            @include footerParagraph;
            margin: 0;
            padding: 0;
            color: $darkGrey;
            font-weight: lighter;
        }
    }
}

@mixin footereBeforeStyleDesktop {
    content: "";
    position: absolute;
    background-color: $blue;
    height: 1rem;
    width: 0;
    transition: 0.25s all;
    border-radius: 3rem;
    z-index: -1;
    transform: translateY(0.25rem);
}

@mixin footerHeading {
    font-size: clamp(2rem, 1.2vw, 2.2rem);
    font-family: $fontFamily;
    font-weight: 700;
    color: $blue;
    text-transform: capitalize;
}

@mixin footerParagraph {
    font-family: $fontFamily;
    font-size: clamp(1.25rem, 0.8vw, 1.5rem);
    font-weight: 600;
    color: $white;
    text-decoration: none;
    list-style: none;
    line-height: 2.75rem;
}

@mixin footerParagraphHover {
    color: $grey;
}

// marquee
@mixin marqueeContainer {
    opacity: 100%;
    transition: 0.5s all;
    background-image: linear-gradient(
        to left top,
        #101011,
        #1d2031,
        #253154,
        #27437a,
        #1c56a3
    );
    height: auto;
    width: calc(100% - 5rem);
    border-radius: 2rem;
    padding: 0 2.5rem;
    -webkit-box-shadow: 0px 35px 25px -10px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 35px 25px -10px rgba(0, 0, 0, 0.05);
}

@mixin marqueeTemplate {
    overflow: hidden;

    span {
        @include marquee;
        text-transform: uppercase;
        cursor: pointer;
        padding: 1rem;
    }

    .uxuiDesign:hover {
        @include marqueeHover;
    }
    .graphicDesign:hover {
        @include marqueeHover;
    }
    .architecture:hover {
        @include marqueeHover;
    }
    .frontendDevelopment:hover {
        @include marqueeHover;
    }
    .digitalArtwork:hover {
        @include marqueeHover;
    }
}

@mixin marquee {
    text-transform: capitalize;
    font-size: clamp(6rem, 4.25vw, 8rem);
    font-weight: 700;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $white;
    color: transparent;
    transition: 0.5s all;
}

@mixin marqueeMobile {
    font-size: 5rem;
    font-weight: 700;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $white;
    color: transparent;
    transition: 0.5s all;
}

@mixin marqueeHover {
    color: $white;
    transition: 0.5s all;
}

//navbar
@mixin navHover {
    content: "";
    position: absolute;
    background-color: $blue;
    width: 2rem;
    height: 1rem;
    margin: 3rem 0 0 0;
    opacity: 1;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transition: 0.25s all;
}

@mixin navHoverActive {
    @include navHover;
    opacity: 1;
    transform: scaleY(1);
    transition: 0.25s all;
}

@mixin navToggle {
    @include navHover;
    background-color: $blue;
    transform: translateY(-1.25rem) scaleY(0);
    z-index: -1;
    height: 0.75rem;
    width: 1.5rem;
}

// illustrations
@mixin illustration {
    width: auto;
    height: clamp(25rem, 26vw, 45rem);
}

// mobile toggle
@mixin mobileToggle {
    display: initial;
    content: "";
    position: absolute;
    width: 2.5rem;
    height: 0.3rem;
    background-color: $blue;
    z-index: -1;
    top: 50%;
    transition: 0.5s all;
}

@mixin mobileToggleBefore {
    display: initial;
    content: "";
    position: absolute;
    width: 2.5rem;
    height: 0.35rem;
    background-color: $blue;
    z-index: -1;
    top: 0.75rem;
    transition: 0.5s all;
}

@mixin mobileToggleAfter {
    display: initial;
    content: "";
    position: absolute;
    width: 2.5rem;
    height: 0.3rem;
    background-color: $blue;
    z-index: -1;
    bottom: 0.75rem;
    transition: 0.5s all;
}

// animations

@mixin blink {
    0% {
        background-color: $blue;
    }
    49% {
        background-color: $blue;
    }
    50% {
        background-color: transparent;
    }
    99% {
        background-color: transparent;
    }
    100% {
        background-color: $blue;
    }
}

@mixin scroll {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }
    100% {
        opacity: 1;
    }
}

@mixin slideRight {
    0% {
        opacity: 0;
        transform: translateX(3rem);
    }
    100% {
        opacity: 1;
    }
}

@mixin opactityChange {
    0% {
        opacity: 0;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}

@mixin lineAnimationTitleBefore {
    opacity: 0%;
    transition: 0.5s all;
    transform: translateY(-3rem);
}

@mixin lineAnimationTitleBefore02 {
    opacity: 0%;
    transition: 0.5s all;
    transform: translateY(5rem);
}

@mixin lineAnimationTitleAfter {
    opacity: 100%;
    transition: 0.5s all;
    transform: translateY(0rem);
}

@mixin tanUnderlineAnimation {
    0% {
        width: 0;
    }
    100% {
        width: clamp(33rem, 35vw, 82rem);
    }
}

// contact

@mixin contactContainer {
    width: calc(99% - 1rem);
    padding: 1rem 0 1rem 1rem;
    font-size: $subheadingFiveFontSizeClamp;
    border: solid $blue 1px;
    font-family: $fontFamily;
    resize: vertical;
}

@mixin contactContainerLarge {
    width: calc(99% - 1rem);
    padding: 1rem 0 10rem 1rem;
    font-size: $subheadingFiveFontSizeClamp;
    border: solid $blue 1px;
    font-family: $fontFamily;
    resize: vertical;
}

@mixin contactSubmitButton {
    width: 100%;
    padding: 2rem;
    background-color: $blue;
    color: $white;
    font-size: 1.5rem;
    border: none;
    border-radius: 100rem;
    transition: 0.5s all;
    opacity: 50%;
    cursor: no-drop;
}

@mixin contactSubmitButtonActive {
    width: 100%;
    padding: 2rem;
    background-color: $blue;
    color: $white;
    font-size: 1.5rem;
    border: none;
    border-radius: 100rem;
    cursor: pointer;
    transition: 0.5s all;
    opacity: 100%;
}

// shadow

@mixin shadowTemplate {
    -webkit-box-shadow: 0px 35px 25px -10px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 35px 25px -10px rgba(0, 0, 0, 0.05);
}

@mixin shadowTemplateTwo {
    box-shadow: 5px 0px 19px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 5px 0px 19px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 5px 0px 19px 0px rgba(0, 0, 0, 0.2);
}

@mixin shadowTemplateThree {
    box-shadow: 5px 0px 19px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 0px 19px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 5px 0px 19px 0px rgba(0, 0, 0, 0.5);
}
