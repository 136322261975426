@import "./_variables";

body {
    @include body;
}

html {
    @include bodyDefault;
}

nav {
    @include navBarDesktop;
    top: 0;
}

main {
    @include main;

    header {
        padding: $desktopPadding;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include lineAnimationTitleBefore;

        .title {
            animation: boxOneAnimation 1s;

            ul {
                list-style: none;
                text-decoration: none;
                padding: 0;
                margin: 0;

                li {
                    padding: 0;

                    h1 {
                        @include headingDarkBlue;
                        padding: 0;
                        margin: 0;
                        font-size: $headingFontSizeClamp;
                    }

                    span {
                        @include headingLightBlue;
                        padding: 0;
                        margin: 0;
                        font-size: $headingFontSizeClamp;
                    }

                    h1.titleOutline {
                        -webkit-text-stroke: 1px $darkBlue;
                        color: transparent;
                    }

                    h1.titleOutline span {
                        -webkit-text-stroke: 1px $blue;
                        color: transparent;
                    }
                }
            }
        }

        .portfolioBelow {
            @include scrollBelow;
        }
    }

    header.active {
        @include lineAnimationTitleAfter;
    }

    .background {
        @include backgroundLogo;
    }
}

section {
    @include section;
    height: auto;
    @include lineAnimationTitleBefore;

    .adContainer {
        padding: 4rem 0;
        margin: 4rem 0;
        border-bottom: 1.5px solid $grey;
        border-top: 1.5px solid $grey;
        position: relative;

        span.advertisement {
            position: absolute;
            top: 5%;
            right: 50%;
            transform: translate(50%, -50%);
            padding: 1rem 0;
            text-transform: capitalize;
            text-decoration: none !important;
            border: none !important;
            color: $darkGrey;
        }

        ins.adsbygoogle {
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 0rem 0 4rem 0;

        button {
            background-color: $blue;
            color: $white;
            width: 10rem;
            padding: 2rem;
            border: none;
            margin: 0 0 0 1rem;
            border-radius: 0.5rem;
            cursor: pointer;
            text-transform: capitalize;
            transition: 0.25s all;
        }

        button.previous.active {
            background-color: $grey;
            cursor: initial;
            color: $darkGrey;
            transition: 0.25s all;
        }

        button.next.active {
            background-color: $grey;
            cursor: initial;
            color: $darkGrey;
            transition: 0.25s all;
        }
    }

    .cardsContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-column-gap: 2vw;
        grid-row-gap: 2vw;

        a {
            text-decoration: none;
            list-style: none;
            background-color: red;
            background-color: $white;
            @include shadowTemplateTwo;
            border-radius: $borderRadiusTwo;
            transform: translateY(0vw);
            border: 3px solid $white;
            transition: box-shadow 0.25s, transform 0.25s, border 0.25s;

            .cardContainer {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                transition: 0.5s all;

                img {
                    width: calc(100%);
                    height: auto;
                    padding: 0 0 2rem 0;
                    border-radius: $borderRadiusThree $borderRadiusThree 0 0;
                }

                .contents {
                    padding: 0 2rem 2rem 2rem;
                    width: calc(100% - 4rem);
                    height: auto;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    text-decoration: none;
                    list-style: none;

                    h1.header {
                        @include subHeadingFive;
                        text-transform: capitalize;
                        color: $darkBlue;
                        padding: 0 0 1rem 0;
                        margin: 0;
                    }

                    p {
                        @include subHeadingTwo;
                        padding: 0;
                        margin: 0 0 1rem 0;
                        color: $darkGrey;
                    }

                    p.paragraph {
                        font-size: $paragraphFontSizeThreeClamp;
                    }

                    .skillsContainer {
                        p.skills {
                            text-transform: capitalize;
                            color: $darkBlue;
                            font-weight: 600;
                            text-transform: uppercase;
                        }

                        span.red {
                            text-transform: uppercase;
                            color: $red;
                            font-weight: bolder;
                            padding: 0 1rem 0 1rem;
                            font-size: 1vw;
                            border: 1px solid $red;
                            border-radius: $borderRadius;
                            margin: 0 1rem 0 0;
                        }

                        span.blue {
                            text-transform: uppercase;
                            color: $blue;
                            font-weight: bolder;
                            padding: 0 1rem 0 1rem;
                            font-size: 1vw;
                            border: 1px solid $blue;
                            border-radius: $borderRadius;
                            margin: 0 1rem 0 0;
                        }

                        span.yellow {
                            text-transform: uppercase;
                            color: $yellow;
                            font-weight: bolder;
                            padding: 0 1rem 0 1rem;
                            font-size: 1vw;
                            border: 1px solid $yellow;
                            border-radius: $borderRadius;
                            margin: 0 1rem 0 0;
                        }

                        span.green {
                            text-transform: uppercase;
                            color: green;
                            font-weight: bolder;
                            padding: 0 1rem 0 1rem;
                            font-size: 1vw;
                            border: 1px solid green;
                            border-radius: $borderRadius;
                            margin: 0 1rem 0 0;
                        }
                    }
                }

                .lastCard {
                    padding-bottom: 0;
                }
            }
        }

        a:hover {
            transform: translateY(-0.25vw);
            @include shadowTemplateThree;
            border: 3px solid $grey;
            transition: box-shadow 0.25s, transform 0.25s;
        }
    }
}

section.active {
    @include lineAnimationTitleAfter;
}

section.blogContent {
    @include section;
    height: auto;
    margin: 0 0 10rem 0;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 1fr;
    opacity: 100%;

    .contents {
        padding: 15rem 0 0 0;
        width: 100%;
        h1.title {
            @include subHeadingTwo;
            color: $blue;
            font-size: 2rem;
            margin: 0;
            padding: 2rem 0 0 0;
        }

        p {
            @include paragraphTwo;
            padding: 2rem 0 0 0;
        }

        p.date {
            @include paragraph;
            color: $darkGrey;
            padding: 0;
            margin: 0;
        }

        img {
            width: 100%;
            height: auto;
        }

        .buttons {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding: 1rem 0 0 0;

            button.btnBlue {
                @include buttonNeutralHover;
                text-transform: capitalize;
                width: auto;
            }

            button.btnBlue:hover {
                opacity: 0.75;
                transform: translateY(-0.25rem);
            }

            button.btnWhite {
                @include buttonNeutralHover;
                background-image: none;
                background-color: transparent;
                border: 2px solid $blue;
                color: $blue;
                text-transform: capitalize;
                width: auto;
                margin: 0 0 0 2rem;
            }

            button.btnWhite:hover {
                opacity: 0.75;
                transform: translateY(-0.25rem);
            }
        }
    }
}

footer {
    @include defaultFooterDesktop;
}

.breadcrumbs {
    padding: 2.5rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: $blue;
        padding: 1rem 2rem;
        border-radius: 0.5rem;
        background-color: $blue;
        text-decoration: none;
        transform: translateX(0rem);

        img.arrow {
            height: 1.5rem !important;
            width: auto;
            margin: 0 2rem 0 0;
            transition: 0.25s all;
        }

        p {
            color: $white;
            padding: 0 !important;
            margin: 0;
            font-size: 1rem;
            transition: 0.1s all;
        }
    }

    a:hover {
        img.arrow {
            height: 1.5rem !important;
            width: auto;
            margin: 0 2rem 0 0;
            transition: 0.25s all;
            transform: translateX(-0.5rem);
        }
    }
}

@media only screen and (max-width: 1024px) {
    nav {
        @include navBarTablet;
    }

    main {
        @include main;

        header {
            padding: $ipadPadding;
        }

        nav.active {
            @include navBarTabletActive;
        }
    }

    section {
        @include sectionTablet;
        .cardsContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            grid-column-gap: 2vw;
            grid-row-gap: 2vw;

            a {
                text-decoration: none;
                list-style: none;
                background-color: red;
                background-color: $white;
                @include shadowTemplateTwo;
                border-radius: $borderRadiusTwo;
                transform: translateY(0vw);
                border: 3px solid $white;
                transition: box-shadow 0.25s, transform 0.25s, border 0.25s;

                .cardContainer {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    transition: 0.5s all;

                    img {
                        width: calc(100%);
                        height: auto;
                        padding: 0 0 2rem 0;
                        border-radius: $borderRadiusThree $borderRadiusThree 0 0;
                    }

                    .contents {
                        padding: 0 2rem 2rem 2rem;
                        width: calc(100% - 4rem);
                        height: auto;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        text-decoration: none;
                        list-style: none;

                        h1.header {
                            @include subHeadingFour;
                            text-transform: capitalize;
                            color: $darkBlue;
                            padding: 0 0 1rem 0;
                            margin: 0;
                        }

                        p {
                            @include subHeadingTwo;
                            padding: 0;
                            margin: 0 0 1rem 0;
                            color: $darkGrey;
                        }

                        .skillsContainer {
                            p.skills {
                                text-transform: capitalize;
                                color: $darkBlue;
                                font-weight: 600;
                                text-transform: uppercase;
                            }

                            span.red {
                                text-transform: uppercase;
                                color: $red;
                                font-weight: bolder;
                                padding: 0 1rem 0 1rem;
                                font-size: 1.5vw;
                                border: 1px solid $red;
                                border-radius: $borderRadius;
                                margin: 0 1rem 0 0;
                            }

                            span.blue {
                                text-transform: uppercase;
                                color: $blue;
                                font-weight: bolder;
                                padding: 0 1rem 0 1rem;
                                font-size: 1.5vw;
                                border: 1px solid $blue;
                                border-radius: $borderRadius;
                                margin: 0 1rem 0 0;
                            }

                            span.yellow {
                                text-transform: uppercase;
                                color: $yellow;
                                font-weight: bolder;
                                padding: 0 1rem 0 1rem;
                                font-size: 1.5vw;
                                border: 1px solid $yellow;
                                border-radius: $borderRadius;
                                margin: 0 1rem 0 0;
                            }

                            span.green {
                                text-transform: uppercase;
                                color: green;
                                font-weight: bolder;
                                padding: 0 1rem 0 1rem;
                                font-size: 1vw;
                                border: 1px solid green;
                                border-radius: $borderRadius;
                                margin: 0 1rem 0 0;
                            }
                        }
                    }

                    .lastCard {
                        padding-bottom: 0;
                    }
                }
            }

            a:hover {
                transform: translateY(-0.25vw);
                @include shadowTemplateThree;
                border: 3px solid $grey;
                transition: box-shadow 0.25s, transform 0.25s;
            }
        }
    }

    section.blogContent {
        @include sectionTablet;
        height: auto;
        margin: 0 0 10rem 0;
        grid-template-rows: 1fr;
        grid-template-columns: 80% 20%;

        .contents {
            width: 100%;
            h1.title {
                @include subHeadingTwo;
                color: $blue;
                font-size: 2rem;
            }

            h1.date {
                @include subHeadingTwo;
                font-weight: lighter;
                color: $darkGrey;
                font-size: 2rem;
            }

            img {
                height: auto;
                width: 100%;
            }

            .buttons {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;

                button.btnBlue {
                    @include buttonNeutralHover;
                    text-transform: capitalize;
                    width: auto;
                }

                button.btnBlue:hover {
                    opacity: 0.75;
                    transform: translateY(-0.25rem);
                }

                button.btnWhite {
                    @include buttonNeutralHover;
                    background-image: none;
                    background-color: transparent;
                    border: 2px solid $blue;
                    color: $blue;
                    text-transform: capitalize;
                    width: auto;
                }

                button.btnWhite:hover {
                    opacity: 0.75;
                    transform: translateY(-0.25rem);
                }
            }
        }
    }

    footer {
        @include defaultFooterTablet;
    }

    .breadcrumbs {
        padding: 2.5rem 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: $blue;
            padding: 1rem 2rem;
            border-radius: 0.5rem;
            background-color: $blue;

            img.arrow {
                height: 1.5rem !important;
                width: auto;
                margin: 0 2rem 0 0;
                transition: 0.25s all;
            }

            p {
                color: $white;
                padding: 0 !important;
                margin: 0;
                font-size: 1rem;
                transition: 0.1s all;
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    nav {
        @include navBarMobile;
    }

    main {
        @include main;

        nav.active {
            @include navBarMobileActive;
        }

        header {
            height: 100vh;

            padding: $mobilePadding;

            .title {
                ul {
                    li {
                        h1 {
                            font-size: $headingFontSizeClamp02;
                        }

                        span {
                            font-size: $headingFontSizeClamp02;
                        }
                    }
                }
            }
        }

        .background {
            @include backgroundLogoMobile;
        }
    }

    section {
        @include sectionMobile;
        .cardsContainer {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-column-gap: 2vw;
            grid-row-gap: 4rem;

            a {
                text-decoration: none;
                list-style: none;
                background-color: red;
                background-color: $white;
                @include shadowTemplateTwo;
                border-radius: $borderRadiusTwo;
                transform: translateY(0vw);
                border: 3px solid $white;
                transition: box-shadow 0.25s, transform 0.25s, border 0.25s;

                .cardContainer {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    transition: 0.5s all;

                    img {
                        width: calc(100%);
                        height: auto;
                        padding: 0 0 2rem 0;
                        border-radius: $borderRadiusThree $borderRadiusThree 0 0;
                    }

                    .contents {
                        padding: 0 2rem 2rem 2rem;
                        width: calc(100% - 4rem);
                        height: auto;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        text-decoration: none;
                        list-style: none;

                        h1.header {
                            font-size: 2rem;
                            text-transform: capitalize;
                            color: $darkBlue;
                            padding: 0 0 1rem 0;
                            margin: 0;
                        }

                        p {
                            @include subHeadingTwo;
                            padding: 0;
                            margin: 0 0 1rem 0;
                            color: $darkGrey;
                        }

                        .skillsContainer {
                            p.skills {
                                text-transform: capitalize;
                                color: $darkBlue;
                                font-weight: 600;
                                text-transform: uppercase;
                            }

                            span.red {
                                text-transform: uppercase;
                                color: $red;
                                font-weight: bolder;
                                padding: 0 1rem 0 1rem;
                                font-size: 3vw;
                                border: 1px solid $red;
                                border-radius: $borderRadius;
                                margin: 0 1rem 0 0;
                            }

                            span.blue {
                                text-transform: uppercase;
                                color: $blue;
                                font-weight: bolder;
                                padding: 0 1rem 0 1rem;
                                font-size: 3vw;
                                border: 1px solid $blue;
                                border-radius: $borderRadius;
                                margin: 0 1rem 0 0;
                            }

                            span.yellow {
                                text-transform: uppercase;
                                color: $yellow;
                                font-weight: bolder;
                                padding: 0 1rem 0 1rem;
                                font-size: 3vw;
                                border: 1px solid $yellow;
                                border-radius: $borderRadius;
                                margin: 0 1rem 0 0;
                            }

                            span.green {
                                text-transform: uppercase;
                                color: green;
                                font-weight: bolder;
                                padding: 0 1rem 0 1rem;
                                font-size: 3vw;
                                border: 1px solid green;
                                border-radius: $borderRadius;
                                margin: 0 1rem 0 0;
                            }
                        }
                    }

                    .lastCard {
                        padding-bottom: 0;
                    }
                }
            }

            a:hover {
                transform: translateY(-0.25vw);
                @include shadowTemplateThree;
                border: 3px solid $grey;
                transition: box-shadow 0.25s, transform 0.25s;
            }
        }
    }

    section.blogContent {
        @include sectionMobile;
        height: auto;
        margin: 0 0 10rem 0;
        width: auto;
        grid-template-columns: 1fr;
        grid-template-rows: 80% 20%;

        .contents {
            width: calc(100% - 7vw);
            h1.title {
                @include subHeadingTwo;
                color: $blue;
                font-size: 2rem;
            }

            h1.date {
                @include subHeadingTwo;
                font-weight: lighter;
                color: $darkGrey;
                font-size: 2rem;
            }

            img {
                width: 100%;
                height: auto;
            }

            .buttons {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;

                a {
                    width: 100%;
                }

                button.btnBlue {
                    @include buttonNeutralHover;
                    width: 100% !important;
                    text-transform: capitalize;
                    margin: 2rem 0;
                }

                button.btnBlue:hover {
                    width: 100% !important;
                    opacity: 0.75;
                    transform: translateY(-0.25rem);
                }

                button.btnWhite {
                    @include buttonNeutralHover;
                    width: 100% !important;
                    background-image: none;
                    background-color: transparent;
                    border: 2px solid $blue;
                    color: $blue;
                    text-transform: capitalize;
                    margin: 0;
                }

                button.btnWhite:hover {
                    width: 100% !important;
                    opacity: 0.75;
                    transform: translateY(-0.25rem);
                }
            }
        }
    }

    footer {
        @include defaultFooterMobile;
    }

    .breadcrumbs {
        padding: 2.5rem 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: $blue;
            padding: 1rem 2rem;
            border-radius: 0.5rem;
            background-color: $blue;
            width: 100%;

            img.arrow {
                height: 1.5rem !important;
                width: auto;
                margin: 0 2rem 0 0;
                transition: 0.25s all;
            }

            p {
                color: $white;
                padding: 0 !important;
                margin: 0;
                font-size: clamp(1.5rem, 1.25vw, 2.7rem) !important;
                transition: 0.1s all;
            }
        }
    }
}
